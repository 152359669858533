import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Mainlogo from "../../assets/images/logo.svg";
import { useLogout } from "../CustomHooks/useLogout";
import Logo from "../../assets/images/Ranweli_Logo.png";

function NavigationBar() {
	const navigate = useNavigate();

	const logOut = () => {
		logout();
		navigate("/");
	};

	const { logout } = useLogout();

	return (
		<div>
			<Navbar collapseOnSelect expand="lg" style={{ height: "100px" }}>
				<Navbar.Brand
					href="/dashboard"
					className="logo"
					style={{ marginLeft: "40px" }}
				>
					<img className="logo" alt="loyalty.lk" width="175px" src={Logo} />
					{/* Ranweli Hotel */}
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse
					id="responsive-navbar-nav"
					style={{ marginRight: "40px" }}
				>
					<Nav className="me-auto"></Nav>
					<Nav>
						<Nav.Link href="/dashboard">Dashboard</Nav.Link>
						<Nav.Link href="/article">Article</Nav.Link>
						<Nav.Link href="/menu">Menu</Nav.Link>
						<Nav.Link href="/module">Module</Nav.Link>
						<Nav.Link href="/news">News Items</Nav.Link>
						<Button onClick={logOut}>Log Out</Button>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
}

export default NavigationBar;
