import { useState } from "react";
import {
    createUserWithEmailAndPassword,
  } from "firebase/auth";
import { auth } from "../../Firebase/Config";
import { useAuthContext } from '../CustomHooks/useAuthContext'

export const useSignup = () => {
    const [error, setError] = useState(null)
    const {dispatch} = useAuthContext()

    const signup = async (email, password) => {
        setError(null)
        await createUserWithEmailAndPassword(auth, email, password)
        .then((res) => {
            dispatch({type: 'LOGIN', payload: res.user})
        }).catch((err) => {
            setError(err.message)
        })
    }

    return { error, signup}
}