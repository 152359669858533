import React from 'react';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

function MenuTable({
	allMenues,
	selectAll,
	allSelected,
	selectAnItem,
	selectedItems,
}) {
	return (
		<div>
			<div>
				<table className='table table-hover table-bordered'>
					<thead>
						<tr>
							<th scope='col'>
								<Form.Check.Input
									onChange={e => {
										selectAll(e.target.checked);
									}}
									checked={allSelected}
								/>
							</th>
							<th scope='col'>Menu Name</th>
							<th scope='col'>URL</th>
							<th scope='col'>Order</th>
							<th scope='col'>Created Date</th>
							<th scope='col'>Status</th>
						</tr>
					</thead>
					<tbody>
						{allMenues &&
							allMenues.map((data, index) => (
								<tr id={data.id} key={index}>
									<td>
										<Form.Check.Input
											onChange={e => {
												selectAnItem(e.target.checked, data.id);
											}}
											checked={selectedItems.includes(data.id)}
										/>
									</td>
									<td>
										<Link to={`/menu/editMenu/${data.id}`}>
											{data.menu_name}
										</Link>
									</td>
									<td>
										<a href={data.blog_link} target='new'>
											{data.menu_url}
										</a>
									</td>
									<td>{data.menu_order}</td>
									<td>{moment(data.createdAt).format('DD-MM-YYYY HH:mm A')}</td>
									<td>
										{' '}
										{(data.status == 1 && (
											<FaCheckCircle
												className='icons'
												style={{ color: 'green' }}
											/>
										)) || (
											<FaTimesCircle
												className='icons'
												style={{ color: 'red' }}
											/>
										)}{' '}
									</td>
								</tr>
							))}
						{!allMenues ||
							(allMenues.length == 0 && (
								<tr>
									<td colSpan={8} className='text-center'>
										No Data
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default MenuTable;
