import firebase from "firebase/compat/app";
// import * as firebase from "firebase/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { getAuth } from "firebase/auth";

// import "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyDSMDr0Q2pSGWAHdwku2HGjspezabFwaKg",
	authDomain: "ranweli-app.firebaseapp.com",
	projectId: "ranweli-app",
	storageBucket: "ranweli-app.appspot.com",
	messagingSenderId: "77880290123",
	appId: "1:77880290123:web:6f5fe273aa030c3ad016ce",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const projectFirestore = firebaseApp.firestore();
const storage = firebase.storage();

const auth = getAuth(firebaseApp);

export { projectFirestore, storage, auth };
