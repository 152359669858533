import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import "./Login.css";
import { useLogin } from "../../CustomHooks/useLogin";

function Login() {
	const [loginEmail, setLoginEmail] = useState("");
	const [loginPassword, setLoginPassword] = useState("");
	const [pageLoader, setPageLoader] = useState(false);
	const [errors, setErrors] = useState({});

	const navigate = useNavigate();

	const { error, login } = useLogin();

	const loginToLoyalty = async () => {
		const newErrors = findFormErrors();
		if (Object.keys(newErrors).length > 0) {
			//We got errors!
			setErrors(newErrors);
		} else {
			await login(loginEmail, loginPassword);
			navigate("/dashboard");
		}
	};

	const findFormErrors = () => {
		const newErrors = {};

		var regex = new RegExp(
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);

		//NAME ERROS
		if (!loginEmail || loginEmail.trim() == "") {
			if (regex.test(loginEmail) === false) {
				newErrors.registerEmail = "Please enter a valid email address";
			}
		}

		if (!loginPassword || loginPassword.trim() == "") {
			newErrors.loginPassword = "Password is required";
		}

		return newErrors;
	};

	return (
		<div className="before-login">
			<div
				className="container"
				style={{
					display: "flex",
					flexDirection: "row",
					marginTop: "20px",
					justifyContent: "center",
				}}
			>
				{error && <alert>{error}</alert>}
				<div className="login-form">
					<div className=" align-items-center">
						{error && <Alert variant="danger">{error}</Alert>}
						<h2 className="mb-4 text-center text-dark font-weight-bold title">
							Ranweli Login
						</h2>

						<Form>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Control
									type="Email"
									placeholder="Email *"
									onChange={(e) => setLoginEmail(e.target.value)}
									value={loginEmail || ""}
									isInvalid={!!errors.loginEmail}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.loginEmail}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mb-3" controlId="formBasicPassword">
								<Form.Control
									type="password"
									placeholder="Password *"
									onChange={(e) => setLoginPassword(e.target.value)}
									value={loginPassword || ""}
									isInvalid={!!errors.loginPassword}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.loginPassword}
								</Form.Control.Feedback>
							</Form.Group>

							<div className=" rounded-0 d-grid gap-2 mx-auto">
								<Button onClick={loginToLoyalty} variant="secondary">
									LOGIN
									{pageLoader && (
										<>
											{" "}
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
										</>
									)}
								</Button>
							</div>
							<div className="mt-4 text-center  text-dark">
								<p className="mt-4 text-center  font-weight-normal">
									Don't have an account ? {/* <a href="/signUp"> */}
									<strong>Contact the Admin</strong>
									{/* </a> */}
								</p>
								<span></span>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
