import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import { useParams, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { projectFirestore, storage } from '../../Firebase/Config';
import { FaTimesCircle } from 'react-icons/fa';
import NavigationBar from '../Navbar/NavigationBar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddAndEditModule() {
	const [isLoading, setIsLoading] = useState(false);
	const [isAnError, setIsAnError] = useState(false);
	const [form, setForm] = useState({});
	const [errors, setErrors] = useState({});
	const navigate = useNavigate();
	const moduleTable = projectFirestore.collection('module');
	const { id } = useParams();
	const [uploadPending, setUploadPending] = useState(false);

	toast.configure({
		autoClose: 2000,
		draggable: true,
		hideProgressBar: true,
	});

	useEffect(() => {
		if (id) {
			//RETRIVE DATA
			setIsLoading(true);
			moduleTable
				.doc(id)
				.get()
				.then(doc => {
					if (doc.exists) {
						setIsLoading(false);
						setForm({ id: doc.id, ...doc.data() });
					} else {
						setIsLoading(false);
						setErrors({ name: 'Could Not Find the Bank' });
					}
				});
		} else {
			// setForm({ name: '', description: '' });
		}
	}, [id]);

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const handleCancel = () => {
		navigate('/module');
	};

	const handleSavenClose = async e => {
		e.preventDefault();
		//get our new errors
		const newErrors = findFormErrors();
		//Conditional Login:
		if (Object.keys(newErrors).length > 0) {
			//We got errors!
			setErrors(newErrors);
		} else {
			//Save
			try {
				setIsLoading(true);
				if (form.id) {
					form.updatedAt = parseInt(moment().format('x'));
					// form.module_description = descriptionEditor;
					await moduleTable.doc(form.id).update(form);
					toast.success('Module Edited Successfully !');
					navigate('/module');
				} else {
					form.createdAt = parseInt(moment().format('x'));
					form.status = 1;
					// form.module_description = descriptionEditor;
					await moduleTable.add(form);
					toast.success('Module Added Successfully !');
					navigate('/module');
				}
				setIsLoading(false);
			} catch (err) {
				setIsAnError(err.message);
			}
		}
	};

	const findFormErrors = () => {
		const newErrors = {};

		return newErrors;
	};

	return (
		<div>
			<div>
				<NavigationBar />
				<div className='container'>
					<div className='version-controller'>
						{isLoading && <Spinner animation='border' />}
						{!form.id && (
							<div>
								<h2>Add Module</h2> <hr />
							</div>
						)}
						{form.id && (
							<div>
								<h1>Edit Module</h1> <hr />
							</div>
						)}
						<Form id={form.id}>
							{/* module name */}
							<Form.Group className='form-group row mb-3'>
								<Form.Label className='col-3'>Module Title *</Form.Label>
								<div className='col-9'>
									<Form.Control
										className='col-9'
										type='text'
										onChange={e => setField('module_title', e.target.value)}
										value={form.module_title || ''}
										isInvalid={!!errors.module_title}
									/>
									<Form.Control.Feedback type='invalid'>
										{errors.module_title}
									</Form.Control.Feedback>
								</div>
							</Form.Group>

							{/* module description */}
							<Form.Group className='form-group row mb-3'>
								<Form.Label className='col-3'>Description</Form.Label>
								<div className='col-9'>
									{/* <ReactQuill
										theme='snow'
										onChange={setDescriptionEditor}
										value={descriptionEditor || form.module_description || ''}
										modules={modules}
										// formats={Editor.formats}
										bounds={'.app'}
										// placeholder={this.props.placeholder}
									/> */}
									<Form.Control
										as='textarea'
										// rows={3}
										value={form.module_description || ''}
										onChange={e =>
											setField('module_description', e.target.value)
										}
									/>

									<Form.Control.Feedback type='invalid'>
										{/* {errors.module_description} */}
									</Form.Control.Feedback>
								</div>
							</Form.Group>

							{/* module link */}
							<Form.Group className='form-group row mb-3'>
								<Form.Label className='col-3'>Page *</Form.Label>
								<div className='col-9'>
									<Form.Control
										className='col-9'
										type='text'
										onChange={e => setField('module_page', e.target.value)}
										value={form.module_page || ''}
										isInvalid={!!errors.module_page}
									/>
									<Form.Control.Feedback type='invalid'>
										{errors.module_page}
									</Form.Control.Feedback>
								</div>
							</Form.Group>

							{/* image */}

							{/* <Form.Group className='form-group row mb-3'>
								<Form.Label className='col-3'>Article Image</Form.Label>
								{uploadPending && <Spinner animation='border' />}
								{!form.logo && !uploadPending && (
									<div className='col-9'>
										<Form.Control
											type='file'
											accept='image/*'
											className='col-9 hidden'
											onChange={handleImage}
										/>
									</div>
								)}

								{form.module_image && (
									<div className='col-9'>
										<img
											alt='image'
											className='image-logo'
											src={form.module_image}
										/>
										<FaTimesCircle
											className='react-fa'
											onClick={handleCancelUrl}
										/>
									</div>
								)}
							</Form.Group> */}

							{form.id && (
								<Form.Group className='form-group row mb-3'>
									<Form.Label className='col-3'>Status </Form.Label>
									<div className='col-9'>
										<Form.Select
											value={form.status}
											isInvalid={!!errors.status}
											onChange={e =>
												setField('status', parseInt(e.target.value))
											}
										>
											<option value='0'>Disable</option>
											<option value='1'>Enable</option>
										</Form.Select>
										<Form.Control.Feedback type='invalid'>
											{errors.status}
										</Form.Control.Feedback>
									</div>
								</Form.Group>
							)}
							<div className='text-end'>
								{/* btn cancle */}
								<Button onClick={handleCancel} variant='danger'>
									Cancel
								</Button>

								{/* btn save */}
								<Button
									disabled={isLoading || uploadPending}
									style={{ margin: '5px' }}
									onClick={handleSavenClose}
									variant='success'
								>
									Save
								</Button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddAndEditModule;
