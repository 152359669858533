import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import { useParams, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { projectFirestore, storage } from '../../Firebase/Config';
import { FaTimesCircle } from 'react-icons/fa';
import NavigationBar from '../Navbar/NavigationBar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddAndEditContactDetails() {
	const [isLoading, setIsLoading] = useState(false);
	const [isAnError, setIsAnError] = useState(false);
	const [form, setForm] = useState({});
	const [errors, setErrors] = useState({});
	const navigate = useNavigate();
	const newsTable = projectFirestore.collection('newsItems');
	const { id } = useParams();
	const [uploadPending, setUploadPending] = useState(false);
	const [descriptionEditor, setDescriptionEditor] = useState('');

	toast.configure({
		autoClose: 2000,
		draggable: true,
		hideProgressBar: true,
	});

	useEffect(() => {
		if (id) {
			//RETRIVE DATA
			setIsLoading(true);
			newsTable
				.doc(id)
				.get()
				.then(doc => {
					if (doc.exists) {
						setIsLoading(false);
						setForm({ id: doc.id, ...doc.data() });
					} else {
						setIsLoading(false);
						setErrors({ name: 'Could Not Find the Bank' });
					}
				});
		} else {
			// setForm({ name: '', description: '' });
		}
	}, [id]);

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const handleCancel = () => {
		navigate('/news');
	};

	const handleSavenClose = async e => {
		e.preventDefault();
		//get our new errors
		const newErrors = findFormErrors();
		//Conditional Login:
		if (Object.keys(newErrors).length > 0) {
			//We got errors!
			setErrors(newErrors);
		} else {
			//Save
			try {
				setIsLoading(true);
				if (form.id) {
					form.updatedAt = parseInt(moment().format('x'));
					// form.blog_description = descriptionEditor;
					await newsTable.doc(form.id).update(form);
					toast.success('Blog Edited Successfully !');
					navigate('/news');
				} else {
					form.createdAt = parseInt(moment().format('x'));
					form.status = 1;
					// form.blog_description = descriptionEditor;
					await newsTable.add(form);
					toast.success('Blog Added Successfully !');
					navigate('/news');
				}
				setIsLoading(false);
			} catch (err) {
				setIsAnError(err.message);
			}
		}
	};

	//IMAGE UPLOAD
	function handleImage(e) {
		e.preventDefault();
		let pickedImage;
		if (e.target.files && e.target.files.length > 0) {
			pickedImage = e.target.files[0];
			// setImage(pickedImage);
			const uploadTask = storage
				.ref('newsImage')
				.child('newsImage' + pickedImage.name)
				.put(pickedImage);
			uploadTask.on(
				'State_changed',
				snapshot => {
					setUploadPending(true);
					let progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				},
				err => {
					console.log(err);
				},
				() => {
					storage
						.ref('newsImage')
						.child('newsImage' + pickedImage.name)
						.getDownloadURL()
						.then(imageUrl => {
							setField('news_image', imageUrl);
							setUploadPending(false);
						});
				}
			);
		}
	}

	function handleCancelUrl() {
		setField('news_image', '');
	}

	const findFormErrors = () => {
		const { news_header, news_description, news_url } = form;
		const newErrors = {};

		//Check Errors
		if (!news_header || news_header.trim() == '') {
			newErrors.news_header = 'News header is required';
		}

		if (!news_description || news_description.trim() == '') {
			newErrors.news_description = 'News Description is required';
		}

		if (!news_url || news_url.trim() == '') {
			newErrors.news_url = 'contact mobile is required';
		}

		return newErrors;
	};

	return (
		<div>
			<NavigationBar />
			<div className='container'>
				<div className='version-controller'>
					{isLoading && <Spinner animation='border' />}
					{!form.id && (
						<div>
							<h2>Add News</h2> <hr />
						</div>
					)}
					{form.id && (
						<div>
							<h1>Edit News</h1> <hr />
						</div>
					)}
					<Form id={form.id}>
						{/* Header */}
						<Form.Group className='form-group row mb-3'>
							<Form.Label className='col-3'>News Header *</Form.Label>
							<div className='col-9'>
								<Form.Control
									className='col-9'
									type='text'
									onChange={e => setField('news_header', e.target.value)}
									value={form.news_header || ''}
									isInvalid={!!errors.news_header}
								/>
								<Form.Control.Feedback type='invalid'>
									{errors.news_header}
								</Form.Control.Feedback>
							</div>
						</Form.Group>

						{/* address */}
						<Form.Group className='form-group row mb-3'>
							<Form.Label className='col-3'>News Description *</Form.Label>
							<div className='col-9'>
								<Form.Control
									className='col-9'
									type='text'
									onChange={e => setField('news_description', e.target.value)}
									value={form.news_description || ''}
									isInvalid={!!errors.news_description}
								/>
								<Form.Control.Feedback type='invalid'>
									{errors.news_description}
								</Form.Control.Feedback>
							</div>
						</Form.Group>

						{/* phone */}
						<Form.Group className='form-group row mb-3'>
							<Form.Label className='col-3'>News URL *</Form.Label>
							<div className='col-9'>
								<Form.Control
									className='col-9'
									type='text'
									onChange={e => setField('news_url', e.target.value)}
									value={form.news_url || ''}
									isInvalid={!!errors.news_url}
								/>
								<Form.Control.Feedback type='invalid'>
									{errors.news_url}
								</Form.Control.Feedback>
							</div>
						</Form.Group>

						{/* image */}
						<Form.Group className='form-group row mb-3'>
							<Form.Label className='col-3'>Article Image</Form.Label>
							{uploadPending && <Spinner animation='border' />}
							{!form.news_image && !uploadPending && (
								<div className='col-9'>
									<Form.Control
										type='file'
										accept='image/*'
										className='col-9 hidden'
										onChange={handleImage}
									/>
								</div>
							)}

							{form.news_image && (
								<div className='col-9'>
									<img
										alt='image'
										className='image-logo'
										src={form.news_image}
									/>
									<FaTimesCircle
										className='react-fa'
										onClick={handleCancelUrl}
									/>
								</div>
							)}
						</Form.Group>

						{form.id && (
							<Form.Group className='form-group row mb-3'>
								<Form.Label className='col-3'>Status </Form.Label>
								<div className='col-9'>
									<Form.Select
										value={form.status}
										isInvalid={!!errors.status}
										onChange={e => setField('status', parseInt(e.target.value))}
									>
										<option value='0'>Disable</option>
										<option value='1'>Enable</option>
									</Form.Select>
									<Form.Control.Feedback type='invalid'>
										{errors.status}
									</Form.Control.Feedback>
								</div>
							</Form.Group>
						)}
						<div className='text-end'>
							{/* btn cancle */}
							<Button onClick={handleCancel} variant='danger'>
								Cancel
							</Button>

							{/* btn save */}
							<Button
								disabled={isLoading || uploadPending}
								style={{ margin: '5px' }}
								onClick={handleSavenClose}
								variant='success'
							>
								Save
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default AddAndEditContactDetails;
