import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavigationBar from '../Navbar/NavigationBar';
import { projectFirestore } from '../../Firebase/Config';
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';
import { FaTimesCircle } from 'react-icons/fa';
import Pagination from 'react-bootstrap/Pagination';
import Spinner from 'react-bootstrap/Spinner';
import ContactDetalisTable from './ContactDetalisTable';

function ContactDetails() {
	const [data, setData] = useState(null);
	const [allSelected, setAllSelected] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);
	const [show, setShow] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [isLoading, setIsLoading] = useState(false);
	const [pageArray, setPageArray] = useState([]);
	const newsTable = projectFirestore.collection('newsItems');
	const batch = projectFirestore.batch();
	const searchRef = useRef(null);
	const [categorySearch, setCategorySearch] = useState(null);
	const [pageLoader, setPageLoader] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(async () => {
		fetchData(currentPage);
	}, []);

	//Pagination Function Start
	async function fetchData(page) {
		setIsLoading(true);
		var currentObj = '';
		const full = newsTable.orderBy('createdAt', 'desc').limit(50);
		const fullsnapshot = await full.get(); // ALL USERS

		if (page - 1 > 0) {
			var nextData = fullsnapshot.docs[(page - 1) * pageSize - 1];
			currentObj = newsTable
				.orderBy('createdAt', 'asc')
				.startAfter(nextData.data().createdAt)
				.limit(pageSize);
		} else {
			currentObj = newsTable.orderBy('createdAt', 'desc').limit(pageSize);
		}

		const snapshot = await currentObj.get();
		// setNextData(snapshot.docs[snapshot.docs.length - 1]); // NEXT DATA

		var tempBlogs = [];

		for (var i = 0; i < snapshot.docs.length; i++) {
			var doc = snapshot.docs[i];
			var item = await doc.data();

			tempBlogs.push({
				id: doc.id,
				news_header: item.news_header,
				news_description: item.news_description,
				news_image: item.news_image,
				news_url: item.news_url,
				createdAt: item.createdAt,
				status: item.status,
			});
		}
		setIsLoading(false);
		setData(tempBlogs);

		var temppageArray = [];
		var toPage = 0;
		var frompage = currentPage;
		var pageCount =
			fullsnapshot.docs.length > 0
				? Math.ceil(fullsnapshot.docs.length / pageSize)
				: 0; // 16

		// 0 123 -4 Page- 5 6 7 8 9 10 11 12 13 14 15 16 ( rows 160 , Pages 16)

		if (currentPage + 10 < pageCount) {
			toPage = currentPage + 10; // 14 Pages
		} else {
			toPage = pageCount; // 16
		}

		if (currentPage - 10 <= 0) {
			frompage = 1;
		} else {
			frompage = currentPage - 10;
		}

		for (var i = frompage; i <= toPage; i++) {
			temppageArray.push(i);
		}
		setPageArray(temppageArray);
	}
	//Pagination Function End

	// Pagniation on button click
	const handleClick = async e => {
		// setData([]);
		setCurrentPage(e.target.id);

		fetchData(e.target.id);
	};

	// Next Button
	const handleNextBtn = () => {
		// setData([]);
		setCurrentPage(currentPage + 1);
		fetchData(currentPage + 1);
	};

	// Previous Button
	const handlePreviousBtn = () => {
		// setData([]);
		setCurrentPage(currentPage - 1);
		fetchData(currentPage - 1);
	};

	// DELETE FUNCTIONS START
	function selectAll(selected) {
		var items = [];
		if (selected) {
			setAllSelected(true);
			for (var i = 0; i < data.length; i++) {
				items.push(data[i].id);
			}
		} else {
			setAllSelected(false);
		}

		setSelectedItems(items);
	}

	// SELECT AN ITEM
	function select(status, id) {
		setAllSelected(false);
		var items = [...selectedItems];
		if (status == false) {
			// IF STATUS FALSE REMOVE FROM SELECTED ITEMS
			_.remove(items, function (item) {
				return item == id;
			});
			setSelectedItems(items);
		} else {
			// ELSE ADD THE ITEM
			items.push(id);
			setSelectedItems(items);
		}
	}

	async function deleteData() {
		setIsLoading(true);
		//  await organisationDb.doc(selectedItems).delete();
		for (var i = 0; i <= selectedItems.length; i++) {
			const laRef = newsTable.doc(selectedItems[i]);
			batch.delete(laRef);
		}

		try {
			await batch.commit();

			_.remove(data, function (items) {
				return selectedItems.includes(items.id);
			});

			setSelectedItems([]);
			fetchData(currentPage);
			handleClose();
		} catch (error) {}

		setIsLoading(false);
	}
	//DELETE FUNCTIONS END

	async function handleSearch(e) {
		e.preventDefault();
		setPageLoader(true);
		await newsTable
			.where('bank_name', '>=', searchRef.current.value.toUpperCase())
			.where(
				'bank_name',
				'<=',
				searchRef.current.value.toUpperCase() + '\uf8ff'
			)
			.onSnapshot(querySnapshot => {
				const items = [];
				querySnapshot.forEach(doc => {
					items.push({ id: doc.id, ...doc.data() });
				});
				setData(items);
				setCategorySearch(searchRef.current.value);
			});
		setPageLoader(false);
	}

	return (
		<div>
			<NavigationBar />
			<div className='container category-controller mt-5'>
				{/* POPUP MODAL */}
				<Modal show={show} onHide={handleClose}>
					<Modal.Body>
						<FaTimesCircle className='trashIcon' />
						<h6 className='removeHeader'>Are you sure you want to delete ?</h6>

						<Button variant='outline-secondary' onClick={handleClose}>
							Close
						</Button>
						<Button
							className='deleteButton'
							variant='outline-danger'
							onClick={deleteData}
						>
							Delete
						</Button>
					</Modal.Body>
				</Modal>
				{/* POPUP MODAL END */}

				<div className='page-btn-title d-flex justify-content-between align-items-center'>
					<h2>News Items Manager</h2>
					<div>
						<Button
							style={{ margin: '5px' }}
							onClick={handleShow}
							variant='danger'
						>
							Delete
						</Button>
						<Link to={'/news/addNews'}>
							<Button variant='success'>Add News Items</Button>
						</Link>
					</div>
				</div>
				<hr />

				<div className='row'>
					<div className='col-md-3 mb-3'>
						<input
							ref={searchRef}
							placeholder='Search by name'
							type='text'
							className='form-control'
							aria-describedby='button-addon2'
						/>
					</div>
					<div className=' page-btn-title col-2'>
						<Button variant='success' onClick={handleSearch}>
							Search
							{pageLoader && (
								<Spinner
									as='span'
									animation='border'
									size='sm'
									role='status'
									aria-hidden='true'
								/>
							)}
						</Button>
					</div>
				</div>

				<div>
					<div>
						<div>
							{isLoading && (
								<div className='d-flex justify-content-center'>
									<div>
										<Spinner animation='border' />
									</div>
								</div>
							)}
							<div className='table-responsive'>
								{data && (
									<ContactDetalisTable
										allContactDetails={data}
										selectAll={selectAll}
										allSelected={allSelected}
										selectAnItem={select}
										selectedItems={selectedItems}
									/>
								)}
							</div>
						</div>
					</div>
				</div>

				<div>
					<Pagination style={{ float: 'right' }}>
						<Pagination.Prev
							onClick={handlePreviousBtn}
							className={currentPage === pageArray[0] ? 'disabled' : '    '}
						/>
						{pageArray.map(page => (
							<Pagination.Item
								key={page}
								id={page}
								onClick={handleClick}
								className={currentPage === page && 'active'}
							>
								{page}
							</Pagination.Item>
						))}
						<Pagination.Next
							onClick={handleNextBtn}
							className={currentPage === pageArray.length ? 'disabled' : ''}
						/>
					</Pagination>
				</div>
			</div>
		</div>
	);
}

export default ContactDetails;
