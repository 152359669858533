import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import { useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { projectFirestore, storage } from "../../Firebase/Config";
import { FaTimesCircle } from "react-icons/fa";
import NavigationBar from "../Navbar/NavigationBar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function AddAndEditBlog() {
	const [isLoading, setIsLoading] = useState(false);
	const [isAnError, setIsAnError] = useState(false);
	const [form, setForm] = useState({});

	const [errors, setErrors] = useState({});
	const navigate = useNavigate();
	const blogTable = projectFirestore.collection("blog");
	const { id } = useParams();
	const [uploadPending, setUploadPending] = useState(false);
	const [descriptionEditor, setDescriptionEditor] = useState("");

	// console.log('descriptionEditor', descriptionEditor);

	toast.configure({
		autoClose: 2000,
		draggable: true,
		hideProgressBar: true,
	});

	const modules = {
		toolbar: [
			[{ header: "1" }, { header: "2" }, { font: [] }],
			[{ size: [] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[
				{ list: "ordered" },
				{ list: "bullet" },
				{ indent: "-1" },
				{ indent: "+1" },
			],
			["link", "image", "video", "code-block"],
			["clean"],
		],
		clipboard: {
			// toggle to add extra line breaks when pasting HTML:
			matchVisual: false,
		},
	};

	useEffect(() => {
		if (id) {
			//RETRIVE DATA
			setIsLoading(true);
			blogTable
				.doc(id)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setIsLoading(false);
						setForm({ id: doc.id, ...doc.data() });
					} else {
						setIsLoading(false);
						setErrors({ name: "Could Not Find the Bank" });
					}
				});
		} else {
			// setForm({ name: '', description: '' });
		}
	}, [id]);

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const handleCancel = () => {
		navigate("/article");
	};

	const handleSavenClose = async (e) => {
		e.preventDefault();
		//get our new errors
		const newErrors = findFormErrors();
		//Conditional Login:
		if (Object.keys(newErrors).length > 0) {
			//We got errors!
			setErrors(newErrors);
		} else {
			//Save
			try {
				setIsLoading(true);
				if (form.id) {
					form.updatedAt = parseInt(moment().format("x"));
					// form.blog_description = descriptionEditor;
					await blogTable.doc(form.id).update(form);
					toast.success("Blog Edited Successfully !");
					navigate("/article");
				} else {
					form.createdAt = parseInt(moment().format("x"));
					form.status = 1;
					// form.blog_description = descriptionEditor;
					await blogTable.add(form);
					toast.success("Blog Added Successfully !");
					navigate("/article");
				}
				setIsLoading(false);
			} catch (err) {
				setIsAnError(err.message);
			}
		}
	};

	//IMAGE UPLOAD
	function handleImage(e) {
		e.preventDefault();
		let pickedImage;
		if (e.target.files && e.target.files.length > 0) {
			pickedImage = e.target.files[0];
			// setImage(pickedImage);
			const uploadTask = storage
				.ref("BlogImage")
				.child("BlogImage" + pickedImage.name)
				.put(pickedImage);
			uploadTask.on(
				"State_changed",
				(snapshot) => {
					setUploadPending(true);
					let progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				},
				(err) => {
					console.log(err);
				},
				() => {
					storage
						.ref("BlogImage")
						.child("BlogImage" + pickedImage.name)
						.getDownloadURL()
						.then((imageUrl) => {
							setField("blog_image", imageUrl);
							setUploadPending(false);
						});
				}
			);
		}
	}

	function handleCancelUrl() {
		setField("blog_image", "");
	}

	const findFormErrors = () => {
		const { blog_name, blog_description, blog_link } = form;
		const newErrors = {};

		//Check Errors
		if (!blog_name || blog_name.trim() == "") {
			newErrors.blog_name = "Blog name is required";
		}

		// if (!blog_description || blog_description.trim() == '') {
		// 	newErrors.blog_description = 'Description name is required';
		// }

		// if (!blog_link || blog_link.trim() == '') {
		// 	newErrors.blog_link = 'Link is required';
		// }

		return newErrors;
	};

	return (
		<div>
			<div>
				<NavigationBar />
				<div className="container">
					<div className="version-controller">
						{isLoading && <Spinner animation="border" />}
						{!form.id && (
							<div>
								<h2>Add Article</h2> <hr />
							</div>
						)}
						{form.id && (
							<div>
								<h1>Edit Article</h1> <hr />
							</div>
						)}
						<Form id={form.id}>
							{/* blog name */}
							<Form.Group className="form-group row mb-3">
								<Form.Label className="col-3">Article Name *</Form.Label>
								<div className="col-9">
									<Form.Control
										className="col-9"
										type="text"
										onChange={(e) => setField("blog_name", e.target.value)}
										value={form.blog_name || ""}
										isInvalid={!!errors.blog_name}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.blog_name}
									</Form.Control.Feedback>
								</div>
							</Form.Group>

							{/* blog description */}
							<Form.Group className="form-group row mb-3">
								<Form.Label className="col-3">Description</Form.Label>
								<div className="col-9">
									{/* <CKEditor
										editor={ClassicEditor}
										data={form.blog_description}
										onChange={(event, editor) => {
											const data = editor.getData();
											setField("blog_description", data);
										}}
									/> */}
								</div>
							</Form.Group>

							{/* blog link */}
							<Form.Group className="form-group row mb-3">
								<Form.Label className="col-3">SLUG *</Form.Label>
								<div className="col-9">
									<Form.Control
										className="col-9"
										type="text"
										onChange={(e) => setField("blog_slug", e.target.value)}
										value={form.blog_slug || ""}
										isInvalid={!!errors.blog_slug}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.blog_link}
									</Form.Control.Feedback>
								</div>
							</Form.Group>

							{/* image */}
							<Form.Group className="form-group row mb-3">
								<Form.Label className="col-3">Article Image</Form.Label>
								{uploadPending && <Spinner animation="border" />}
								{!form.logo && !uploadPending && (
									<div className="col-9">
										<Form.Control
											type="file"
											accept="image/*"
											className="col-9 hidden"
											onChange={handleImage}
										/>
									</div>
								)}

								{form.blog_image && (
									<div className="col-9">
										<img
											alt="image"
											className="image-logo"
											src={form.blog_image}
										/>
										<FaTimesCircle
											className="react-fa"
											onClick={handleCancelUrl}
										/>
									</div>
								)}
							</Form.Group>

							{form.id && (
								<Form.Group className="form-group row mb-3">
									<Form.Label className="col-3">Status </Form.Label>
									<div className="col-9">
										<Form.Select
											value={form.status}
											isInvalid={!!errors.status}
											onChange={(e) =>
												setField("status", parseInt(e.target.value))
											}
										>
											<option value="0">Disable</option>
											<option value="1">Enable</option>
										</Form.Select>
										<Form.Control.Feedback type="invalid">
											{errors.status}
										</Form.Control.Feedback>
									</div>
								</Form.Group>
							)}
							<div className="text-end">
								{/* btn cancle */}
								<Button onClick={handleCancel} variant="danger">
									Cancel
								</Button>

								{/* btn save */}
								<Button
									disabled={isLoading || uploadPending}
									style={{ margin: "5px" }}
									onClick={handleSavenClose}
									variant="success"
								>
									Save
								</Button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddAndEditBlog;
