import React from 'react';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

function ContactDetalisTable({
	allContactDetails,
	selectAll,
	allSelected,
	selectAnItem,
	selectedItems,
}) {
	return (
		<div>
			<table className='table table-hover table-bordered'>
				<thead>
					<tr>
						<th scope='col'>
							<Form.Check.Input
								onChange={e => {
									selectAll(e.target.checked);
								}}
								checked={allSelected}
							/>
						</th>
						<th scope='col'>News Header</th>
						<th scope='col'>Image</th>
						<th scope='col'>Description</th>
						<th scope='col'>News URL</th>
						<th scope='col'>Created Date</th>
						<th scope='col'>Status</th>
					</tr>
				</thead>
				<tbody>
					{allContactDetails &&
						allContactDetails.map((data, index) => (
							<tr id={data.id} key={index}>
								<td>
									<Form.Check.Input
										onChange={e => {
											selectAnItem(e.target.checked, data.id);
										}}
										checked={selectedItems.includes(data.id)}
									/>
								</td>

								<td>
									<Link to={`/news/editNews/${data.id}`}>
										{data.news_header}
									</Link>
								</td>
								<td>
									<img
										alt='image-slider'
										className='image-logo'
										src={data.news_image}
									/>
								</td>
								<td>{data.news_description}</td>
								<td>
									<a href={data.news_url} target='new'>
										{data.news_url}
									</a>
								</td>
								<td>{moment(data.createdAt).format('DD-MM-YYYY HH:mm A')}</td>
								<td>
									{' '}
									{(data.status == 1 && (
										<FaCheckCircle
											className='icons'
											style={{ color: 'green' }}
										/>
									)) || (
										<FaTimesCircle className='icons' style={{ color: 'red' }} />
									)}{' '}
								</td>
							</tr>
						))}
					{!allContactDetails ||
						(allContactDetails.length == 0 && (
							<tr>
								<td colSpan={8} className='text-center'>
									No Data
								</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
}

export default ContactDetalisTable;
