import React from "react";
import NavigationBar from "../Navbar/NavigationBar";
import Mainlogo from "../../assets/images/Asset_3.svg";

function Dashboard() {
	return (
		<div>
			{/* <Navbar /> */}
			<NavigationBar />

			<div className="container pt-5 pb-5 dashboard">
				<div className="row">
					<div className="col-lg-12">
						<div className="item pt-5">
							<div className="item-list text-center">
								<br />
								<br />
								{/* <img
									className='logo'
									alt='loyalty.lk'
									width='175px'
									src={Mainlogo}
								/> */}
								<a href="/dashboard" className="logo">
									Ranweli.lk
								</a>
								<hr />
								<br />
								<br />
								<a href="/dashboard" className="badge">
									Dashboard
								</a>
								<a href="/article" className="badge">
									Article
								</a>
								<a href="/menu" className="badge">
									Menu
								</a>
								<a href="/module" className="badge">
									Module
								</a>
								<a href="/news" className="badge">
									News Items
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
