import React, { useEffect, useState } from "react";
import { Form, Button, Alert, Spinner, Modal } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { projectFirestore, storage } from "../../Firebase/Config";
import moment from "moment";
import { FaTimesCircle } from "react-icons/fa";
import NavigationBar from "../Navbar/NavigationBar";
import _ from "lodash";
import AddEditPopUpMenu from "./AddEditPopUpMenu";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
var slugify = require("slugify");

function AddAndEditMenu() {
	const [form, setForm] = useState({});
	const [errors, setErrors] = useState({});
	const [error, setError] = useState(false);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [addOfferShow, setAddOfferShow] = useState(false);

	const [offer, setOffer] = useState({}); // ACTUAL OFFER
	console.log("offer", offer);

	const hanldeOfferClose = () => setAddOfferShow(false);
	const handleOfferShow = () => setAddOfferShow(true);

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const menuTable = projectFirestore.collection("menu");
	const [isAnError, setIsAnError] = useState(false);

	const [terms, setTerms] = useState("");
	const [cardList, setCardList] = useState([]);
	const { id } = useParams();
	const batch = projectFirestore.batch();

	const [allSelected, setAllSelected] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);
	const [isPending, setIsPending] = useState(false);

	const [slug, setSlug] = useState("");
	const [slugSub, setSlugSub] = useState("");

	useEffect(() => {
		const slug = slugify(`${form.menu_name}`);
		setSlug(slug);
	}, [form.menu_name]);

	useEffect(() => {
		const slug = slugify(`${offer.lable}`);
		setSlugSub(slug);
		console.log("test slug", slug);
	}, [offer.lable]);

	useEffect(() => {
		if (id) {
			//RETRIVE DATA
			setIsLoading(true);
			menuTable
				.doc(id)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setIsLoading(false);
						setForm({ id: doc.id, ...doc.data() });
					} else {
						setIsLoading(false);
						setErrors({ name: "Could Not Find the Offer" });
					}
				});
		} else {
			// DEAFULT VALUE SET
			setForm({
				// offer_name: '',
				// merchant: '',
				// offer_category: '',
				// offer_description: '',
				offers: [],
			});
		}
	}, [id]);

	toast.configure({
		autoClose: 2000,
		draggable: true,
		hideProgressBar: true,
	});

	const editOffer = (index) => {
		setOffer(form.offers[index]);
		handleOfferShow(true);
	};

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const setOfferField = (field, value) => {
		setOffer({
			...offer,
			[field]: value,
		});

		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const handleCancel = () => {
		navigate("/menu");
	};

	const findFormErrors = () => {
		const { menu_name, menu_url, menu_order } = form;
		const newErrors = {};

		if (!menu_name || menu_name.trim() == "") {
			newErrors.menu_name = "Menu name is required";
		}

		if (!menu_url || menu_url.trim() == "") {
			newErrors.menu_url = "URL is required";
		}

		// if (!menu_order || menu_order.trim() == '') {
		// 	newErrors.menu_order = 'Order is required';
		// }

		return newErrors;
	};

	const handleSavenClose = async (e) => {
		e.preventDefault();
		//get our new errors
		const newErrors = findFormErrors();
		//Conditional Login:
		if (Object.keys(newErrors).length > 0) {
			//We got errors!
			setErrors(newErrors);
		} else {
			//Save
			try {
				setIsLoading(true);
				if (form.id) {
					form.updatedAt = parseInt(moment().format("x"));
					await menuTable.doc(form.id).update(form);
					toast.success("Menu Edited Successfully !");
					navigate("/menu");
				} else {
					form.createdAt = parseInt(moment().format("x"));
					form.status = 1;
					form.slug = slug;

					await menuTable.add(form);
					toast.success("Menu Added Successfully !");
					navigate("/menu");
				}
				setIsLoading(false);
			} catch (err) {
				setIsAnError(err.message);
			}
		}
	};

	const handleOfferModal = () => {
		var tempMenu = [...form.offers];
		var card = _.find(cardList, { id: offer.card });
		if (!offer.id) {
			offer.id = uuidv4();
			offer.slug = slugSub;
			tempMenu.push(offer);
		} else {
			var index = _.findIndex(tempMenu, { id: offer.id });
			tempMenu[index] = offer;
		}
		setField("offers", tempMenu.length > 0 ? tempMenu : []);
		setAddOfferShow(false);
	};

	// DELETE FUNCTIONS START
	function selectAll(selected) {
		var items = [];

		if (selected) {
			setAllSelected(true);

			for (var i = 0; i < form.offers.length; i++) {
				items.push(form.offers[i].id);
			}
		} else {
			setAllSelected(false);
		}

		setSelectedItems(items);
	}

	// SELECT AN ITEM
	function select(status, id) {
		setAllSelected(false);

		var items = [...selectedItems];

		if (status == false) {
			// IF STATUS FALSE REMOVE FROM SELECTED ITEMS
			_.remove(items, function (item) {
				return item == id;
			});

			setSelectedItems(items);
		} else {
			// ELSE ADD THE ITEM
			items.push(id);
			setSelectedItems(items);
		}
	}

	async function deleteData() {
		setIsPending(true);
		for (var i = 0; i <= selectedItems.length; i++) {
			const laRef = menuTable.doc(selectedItems[i]);
			batch.delete(laRef);
		}

		try {
			await batch.commit();

			_.remove(form.offers, function (items) {
				return selectedItems.includes(items.id);
			});
			setSelectedItems([]);
			handleClose();
		} catch (error) {}
		setIsPending(false);
	}
	//DELETE FUNCTIONS END

	return (
		<div className="offers-component">
			<NavigationBar />
			<div className="container">
				<div className="version-controller">
					{isAnError && <Alert variant="warning">{error}</Alert>}
					{isLoading && <Spinner animation="border" />}
					{!form.id && (
						<div>
							<h2>Add Menu</h2>
						</div>
					)}
					{form.id && (
						<div>
							<h1>Edit Menu</h1>
						</div>
					)}

					<hr />
					<Form id={form.id}>
						<Form.Group className="form-group row mb-3">
							<Form.Label className="col-3">Menu Name *</Form.Label>
							<div className="col-9">
								<Form.Control
									className="col-9"
									type="text"
									onChange={(e) => setField("menu_name", e.target.value)}
									value={form.menu_name || ""}
									isInvalid={!!errors.menu_name}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.menu_name}
								</Form.Control.Feedback>
							</div>
						</Form.Group>

						<Form.Group className="form-group row mb-3">
							<Form.Label className="col-3">Menu URL *</Form.Label>
							<div className="col-9">
								<Form.Control
									className="col-9"
									type="text"
									onChange={(e) => setField("menu_url", e.target.value)}
									value={form.menu_url || ""}
									isInvalid={!!errors.menu_url}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.menu_url}
								</Form.Control.Feedback>
							</div>
						</Form.Group>

						<Form.Group className="form-group row mb-3">
							<Form.Label className="col-3">Slug </Form.Label>
							<div className="col-9">
								<Form.Control
									className="col-9"
									type="text"
									// onChange={e => setField('menu_slug', e.target.value)}
									disabled
									value={slug || form.slug || ""}
									// isInvalid={!!errors.menu_name}
								/>
								<Form.Control.Feedback type="invalid">
									{/* {errors.menu_name} */}
								</Form.Control.Feedback>
							</div>
						</Form.Group>

						<Form.Group className="form-group row mb-3">
							<Form.Label className="col-3">Order</Form.Label>
							<div className="col-9">
								<Form.Control
									className="col-9"
									type="number"
									onChange={(e) =>
										setField("menu_order", parseInt(e.target.value))
									}
									value={form.menu_order || ""}
									isInvalid={!!errors.menu_order}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.menu_order}
								</Form.Control.Feedback>
							</div>
						</Form.Group>

						<div className="offer-poup-section">
							<div className="page-btn-title d-flex  justify-content-between align-items-center">
								<h2 className="text-dark mb-0">Menu Item</h2>
								<div>
									<Button
										style={{ margin: "5px" }}
										onClick={handleShow}
										variant="danger"
									>
										Delete
									</Button>
									<Button variant="success" onClick={handleOfferShow}>
										Add Menu Item
									</Button>
								</div>
							</div>
							<hr />

							{/* POPUP MODAL */}
							<Modal show={show} onHide={handleClose}>
								<Modal.Body>
									<FaTimesCircle className="trashIcon" />
									<h6 className="removeHeader">
										Are you sure you want to delete ?
									</h6>

									<Button variant="outline-secondary" onClick={handleClose}>
										Close
									</Button>
									<Button
										className="deleteButton"
										variant="outline-danger"
										onClick={deleteData}
									>
										Delete
									</Button>
								</Modal.Body>
							</Modal>
							{/* POPUP MODAL END */}

							{isPending && (
								<div className="d-flex justify-content-center">
									<div>
										<Spinner animation="border" />
									</div>
								</div>
							)}

							<AddEditPopUpMenu
								setOfferField={setOfferField}
								setTerms={setTerms}
								form={form}
								handleOfferModal={handleOfferModal}
								cardList={cardList}
								setShow={setShow}
								addOfferShow={addOfferShow}
								setAddOfferShow={setAddOfferShow}
								offer={offer}
								hanldeOfferClose={hanldeOfferClose}
							/>

							<div className="margin">
								<table className="table table-hover table-bordered">
									<thead>
										<tr>
											<th scope="col">
												<Form.Check.Input
													onChange={(e) => {
														selectAll(e.target.checked);
													}}
													checked={allSelected}
												/>
											</th>
											<th scope="col">ID</th>
											<th scope="col">URL</th>
											<th scope="col">Lable</th>
											<th scope="col">SLUG</th>
											<th scope="col">Order</th>
											<th scope="col">Created At</th>
											<th scope="col">Action</th>
										</tr>
									</thead>
									<tbody>
										{console.log(form.offers)}
										{form.offers &&
											form.offers.length > 0 &&
											form.offers.map((data, index) => (
												<tr id={data.id} key={index}>
													<td>
														<Form.Check.Input
															onChange={(e) => {
																select(e.target.checked, data.id);
															}}
															checked={selectedItems.includes(data.id)}
														/>
													</td>
													<td>{data.id}</td>
													<td>
														<a href={data.url} target="new">
															{data.url}
														</a>
													</td>
													<td>{data.lable}</td>
													<td>{data.slug}</td>
													<td>{data.order}</td>
													<td>
														{moment(data.createdAt).format(
															"DD-MM-YYYY HH:mm A"
														)}
													</td>
													<td>
														<Button onClick={() => editOffer(index)}>
															Edit
														</Button>
													</td>
												</tr>
											))}

										{!form.offers ||
											(form.offers.length === 0 && (
												<tr>
													<td colSpan={8} className="text-center">
														No Offers Added
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>

						<div className="text-end">
							{/* btn cancle */}
							<Button onClick={handleCancel} variant="danger">
								Cancel
							</Button>

							{/* btn save */}
							<Button
								// disabled={isFilePending || isPending}
								style={{ margin: "5px" }}
								onClick={handleSavenClose}
								variant="success"
							>
								Save
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default AddAndEditMenu;
