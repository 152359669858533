import React from 'react';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

function BlogTable({
	allBlogs,
	selectAll,
	allSelected,
	selectAnItem,
	selectedItems,
}) {
	return (
		<div>
			<div>
				<table className='table table-hover table-bordered'>
					<thead>
						<tr>
							<th scope='col'>
								<Form.Check.Input
									onChange={e => {
										selectAll(e.target.checked);
									}}
									checked={allSelected}
								/>
							</th>
							<th scope='col'>Article Name</th>
							<th scope='col'>Description</th>
							<th scope='col'>SLUG</th>
							<th scope='col'>Created Date</th>
							<th scope='col'>Status</th>
						</tr>
					</thead>
					<tbody>
						{allBlogs &&
							allBlogs.map((data, index) => (
								<tr id={data.id} key={index}>
									<td>
										<Form.Check.Input
											onChange={e => {
												selectAnItem(e.target.checked, data.id);
											}}
											checked={selectedItems.includes(data.id)}
										/>
									</td>
									<td>
										<Link to={`/article/editArticle/${data.id}`}>
											{data.blog_name}
										</Link>
									</td>
									<td>{data.blog_description}</td>
									<td>
										<a href={data.blog_slug} target='new'>
											{data.blog_slug}
										</a>
									</td>
									<td>{moment(data.createdAt).format('DD-MM-YYYY HH:mm A')}</td>
									<td>
										{' '}
										{(data.status == 1 && (
											<FaCheckCircle
												className='icons'
												style={{ color: 'green' }}
											/>
										)) || (
											<FaTimesCircle
												className='icons'
												style={{ color: 'red' }}
											/>
										)}{' '}
									</td>
								</tr>
							))}
						{!allBlogs ||
							(allBlogs.length == 0 && (
								<tr>
									<td colSpan={8} className='text-center'>
										No Data
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default BlogTable;
