import React, { useState } from "react";
import {
	createUserWithEmailAndPassword,
	onAuthStateChanged,
	signOut,
} from "firebase/auth";
import { auth } from "../../../Firebase/Config";
import { useNavigate } from "react-router-dom";
// import { addUser } from "../../../Features/Users";
import { useDispatch } from "react-redux";
import { projectFirestore } from "../../../Firebase/Config";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useSignup } from "../../CustomHooks/useSignup";

function SignUp() {
	const [registerName, setRegisterName] = useState("");
	const [registerMobileNumber, setRegisterMobileNumber] = useState("");
	const [registerEmail, setRegisterEmail] = useState("");
	const [registerPassWord, setRegisterPassWord] = useState("");
	const [pageLoader, setPageLoader] = useState(false);
	const [errors, setErrors] = useState({});
	const navigate = useNavigate();

	const userTable = projectFirestore.collection("user");

	const { error, signup } = useSignup();

	var registeredUser = {
		name: registerName,
		mobileNumber: registerMobileNumber,
		email: registerEmail,
		password: registerPassWord,
		createdAt: parseInt(moment().format("x")),
		visibility: 1,
		userType: 1,
	};

	const register = async () => {
		const newErrors = findFormErrors();
		if (Object.keys(newErrors).length > 0) {
			//We got errors!
			setErrors(newErrors);
		} else {
			try {
				await signup(registerEmail, registerPassWord);
				var res = await userTable.add(registeredUser);
				navigate("/dashboard");
			} catch (error) {
				console.error(error.message);
			}
		}
	};

	const findFormErrors = () => {
		const newErrors = {};

		var regex = new RegExp(
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
		//NAME ERROS
		if (!registerName || registerName.trim() == "") {
			newErrors.registerName = "Name is required";
		}

		if (!registerMobileNumber || registerMobileNumber.trim() == "") {
			newErrors.registerMobileNumber = "Mobile number is required";
		}

		if (!registerEmail || registerEmail !== "") {
			if (regex.test(registerEmail) === false) {
				newErrors.registerEmail = "Please enter a valid email address";
			}
		}

		if (!registerPassWord || registerPassWord.trim() == "") {
			newErrors.registerPassWord = "Password is required";
		}

		return newErrors;
	};

	return (
		<div className="before-login">
			<div
				className="container"
				style={{
					display: "flex",
					flexDirection: "row",
					marginTop: "20px",
					justifyContent: "center",
				}}
			>
				{error && <alert>{error}</alert>}
				<div className="login-form">
					<div className="align-items-center">
						<h3 className="mb-4 text-center text-dark font-weight-bold title">
							Enter your details to register to Ranweli
						</h3>
						<Form>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Control
									type="text"
									placeholder="Enter Name *"
									onChange={(e) => setRegisterName(e.target.value)}
									value={registerName || ""}
									isInvalid={!!errors.registerName}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.registerName}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Control
									type="number"
									placeholder="Mobile Number *"
									onChange={(e) => setRegisterMobileNumber(e.target.value)}
									value={registerMobileNumber || ""}
									isInvalid={!!errors.registerMobileNumber}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.registerMobileNumber}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Control
									type="email"
									placeholder="Email *"
									onChange={(e) => setRegisterEmail(e.target.value)}
									value={registerEmail || ""}
									isInvalid={!!errors.registerEmail}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.registerEmail}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Control
									type="password"
									placeholder="Password *"
									onChange={(e) => setRegisterPassWord(e.target.value)}
									value={registerPassWord || ""}
									isInvalid={!!errors.registerPassWord}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.registerPassWord}
								</Form.Control.Feedback>
							</Form.Group>

							<div className="rounded-0 d-grid gap-2 mx-auto">
								<Button variant="secondary" onClick={register}>
									REGISTER
									{pageLoader && (
										<>
											{" "}
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
										</>
									)}
								</Button>
								<p className="mt-4 text-center font-weight-normal">
									Already have an account ?{" "}
									<a href="/">
										<strong>Login</strong>
									</a>
								</p>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SignUp;
