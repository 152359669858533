import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
var slugify = require('slugify');

function AddEditPopUpMenu({
	form,
	handleOfferModal,
	cardList,
	setOfferField,
	addOfferShow,
	setAddOfferShow,
	offer,
	hanldeOfferClose,
	// selectedOffer
}) {
	const [errors, setErrors] = useState({});
	const [slug, setSlug] = useState('');

	useEffect(() => {
		const slugSubstitude = slugify(`${offer.lable}`);
		// setSlugSub(slugSubstitude);
		console.log('slugSubstitude', slugSubstitude);
		setSlug(slugSubstitude);
	}, [offer.lable]);

	return (
		<div>
			{/* POPUP MODAL */}

			<Modal
				backdrop='static'
				keyboard={false}
				show={addOfferShow}
				onHide={() => setAddOfferShow(false)}
			>
				<Modal.Header closeButton>
					{!offer.id && <Modal.Title>Add Menu Item</Modal.Title>}
					{offer.id && <Modal.Title>Edit Menu Item</Modal.Title>}
				</Modal.Header>
				<Modal.Body className='offer-modal-show'>
					<Form id={form.id}>
						<Form.Group className='form-group row mb-3'>
							<Form.Label className='col-3'>URL *</Form.Label>
							<div className='col-9'>
								<Form.Control
									className='col-9'
									type='text'
									onChange={e => setOfferField('url', e.target.value)}
									value={offer.url || ''}
								/>
								<Form.Control.Feedback type='invalid'>
									{errors.url}
								</Form.Control.Feedback>
							</div>
						</Form.Group>

						<Form.Group className='form-group row mb-3'>
							<Form.Label className='col-3'>Lable *</Form.Label>
							<div className='col-9'>
								<Form.Control
									className='col-9'
									type='text'
									onChange={e => setOfferField('lable', e.target.value)}
									value={offer.lable || ''}
									isInvalid={!!errors.lable}
								/>
								<Form.Control.Feedback type='invalid'>
									{errors.lable}
								</Form.Control.Feedback>
							</div>
						</Form.Group>

						<Form.Group className='form-group row mb-3'>
							<Form.Label className='col-3'>SLUG</Form.Label>
							<div className='col-9'>
								<Form.Control
									className='col-9'
									type='text'
									disabled
									value={offer.slug || ''}
									isInvalid={!!errors.lable}
								/>
								<Form.Control.Feedback type='invalid'>
									{errors.lable}
								</Form.Control.Feedback>
							</div>
						</Form.Group>

						<Form.Group className='form-group row mb-3'>
							<Form.Label className='col-3'>Order</Form.Label>
							<div className='col-9'>
								<Form.Control
									className='col-9'
									type='number'
									onChange={e =>
										setOfferField('order', parseInt(e.target.value))
									}
									value={offer.order || ''}
									isInvalid={!!errors.lable}
								/>
								<Form.Control.Feedback type='invalid'>
									{errors.lable}
								</Form.Control.Feedback>
							</div>
						</Form.Group>
					</Form>
					<Button variant='secondary' onClick={() => hanldeOfferClose()}>
						Close
					</Button>
					<Button
						className='deleteButton'
						variant='success'
						onClick={() => {
							handleOfferModal();
						}}
					>
						{offer.id ? 'Edit Menu Item' : 'Save Menu Item'}
					</Button>
				</Modal.Body>
			</Modal>

			{/* POPUP MODAL END */}
		</div>
	);
}

export default AddEditPopUpMenu;
