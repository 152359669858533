import React from "react";
import "./assets/styles/App.scss";
import "./assets/js/custom.js";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import SignUp from "./Components/Authentication/SignUp/SignUp";
import Login from "./Components/Authentication/Login/Login";
import { useAuthContext } from "./Components/CustomHooks/useAuthContext";
import Blog from "./Components/Blog/Blog";
import AddAndEditBlog from "./Components/Blog/AddAndEditBlog";
import Menu from "./Components/Menu/Menu";
import AddAndEditMenu from "./Components/Menu/AddAndEditMenu";
import Module from "./Components/Module/Module";
import AddAndEditModule from "./Components/Module/AddAndEditModule";
import ContactDetails from "./Components/ContactDetails/ContactDetails";
import AddAndEditContactDetails from "./Components/ContactDetails/AddAndEditContactDetails";

function App() {
	const { user, authIsReady } = useAuthContext();

	return (
		<div className="App">
			{authIsReady && (
				<BrowserRouter>
					<Routes>
						{/* <Route path="/dashboard" element={ !user ? <Navigate to={"/"} /> : <Dashboard /> }/> */}
						<Route
							path="/article"
							element={user ? <Blog /> : <Navigate to={"/"} />}
						/>
						<Route
							path="/article/addArticle"
							element={user ? <AddAndEditBlog /> : <Navigate to={"/"} />}
						/>
						<Route
							path="/article/editArticle/:id"
							element={user ? <AddAndEditBlog /> : <Navigate to={"/"} />}
						/>
						<Route
							path="/dashboard"
							element={user ? <Dashboard /> : <Navigate to={"/"} />}
						/>

						<Route
							path="/menu"
							element={user ? <Menu /> : <Navigate to={"/"} />}
						/>
						<Route
							path="/menu/addMenu"
							element={user ? <AddAndEditMenu /> : <Navigate to={"/"} />}
						/>
						<Route
							path="/menu/editMenu/:id"
							element={user ? <AddAndEditMenu /> : <Navigate to={"/"} />}
						/>
						<Route
							path="/module"
							element={user ? <Module /> : <Navigate to={"/"} />}
						/>
						<Route
							path="/module/addModule"
							element={user ? <AddAndEditModule /> : <Navigate to={"/"} />}
						/>
						<Route
							path="/module/editModule/:id"
							element={user ? <AddAndEditModule /> : <Navigate to={"/"} />}
						/>
						<Route
							path="/news"
							element={user ? <ContactDetails /> : <Navigate to={"/"} />}
						/>
						<Route
							path="/news/addNews"
							element={
								user ? <AddAndEditContactDetails /> : <Navigate to={"/"} />
							}
						/>
						<Route
							path="/news/editNews/:id"
							element={
								user ? <AddAndEditContactDetails /> : <Navigate to={"/"} />
							}
						/>
						<Route path="/signUp" element={<SignUp />} />
						<Route path="/" element={<Login />} />
					</Routes>
				</BrowserRouter>
			)}
		</div>
	);
}

export default App;
